import React from 'react';
import GlitchText from '@johnn-e/react-glitch-text';

const HomePage = () => {
    return (
    <div>
        <div className="text-4xl">
            
        </div>
    </div>
  )
}

export default HomePage